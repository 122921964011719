import React from 'react';

const Developers = () => (
	<div>
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-container">
						<div className="page-inner text-center">
						<h2 className="text-center text-capitalize">{window.Configs.domain} Developers</h2>
							<div className="boxcon">
								<p className="devdesc"> <i className="fa fa-arrows" aria-hidden="true"></i> Do you have code or an app that could run this brand? <span className="text-capitalize">{window.Configs.domain}</span> is connected with&nbsp;
								<a rel="noopener noreferrer" target="_blank" href={"https://contrib.com/signup/firststep?domain="+window.Configs.domain}>Contrib</a>.
								</p>
								<p className="devdesc"> <i className="fa fa-arrows" aria-hidden="true"></i> 
								<a rel="noopener noreferrer" target="_blank" href={"https://contrib.com/signup/firststep?domain="+window.Configs.domain}>Contrib</a>&nbsp;
								is the new way to contribute and get equity building the world's biggest brands and startups. We have our own Developers platform, and we run the world's best brands on them. Do you have an app, or code that could help run tampazoo.com? 
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default Developers
