import React from 'react';

const Partner = () => (
	<div>
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-container">
						<div className="page-inner text-center">
						<h2 className="text-center">Join Our Partner Network</h2>
							<div className="boxcon">	
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://contrib.com"> <img className="img-fluid" alt="contrib.com" title="contrib.com" src="https://cdn.vnoc.com/logos/logo-Contrib-white-1.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://contrib.com">Contrib.com</a>
											    </h3>
											<p> Our network of Contributors power our domains. Browse through our Marketplace of People, Partnerships,Proposals and Brands and find your next great opportunity. Join Free Today. </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://globalventures.com"> <img className="img-fluid" alt="contrib.com" title="contrib.com" src="https://cdn.vnoc.com/logos/image_logo-gventures10-420x60.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://globalventures.com">GlobalVentures.com</a>
											    </h3>
											<p> Global Ventures owns a premium network of 20,000 websites and powerful tools to help you build successful companies quickly. Some of the things we offer you include a great domain name with targeted traffic, unique business model, equity ownership, and flexible, performance based compensation. You just need to bring your knowledge, passion and work smart. </p>
											<p> With over 17 years of internet experience, we built a network of over 20,000 websites and created dozens of successful businesses. We would love to work on the next cutting-edge projects with great companies and talented people. </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://ifund.com"> <img className="img-fluid" alt="iFund.com" title="iFund" src="https://www.contrib.com/uploads/logo/ifund.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://ifund.com">iFund.com</a>
											    </h3>
											<p> iFund is a software as a service crowdfunding platform. iFund is not a registered broker-dealer and does not offer investment advice or advise on the raising of capital through securities offerings. iFund does not recommend or otherwise suggest that any investor make an investment in a particular company, or that any company offer securities to a particular investor. iFund takes no part in the negotiation or execution of transactions for the purchase or sale of securities, and at no time has possession of funds or securities. No securities transactions are executed or negotiated on or through the iFund platform. iFund receives no compensation in connection with the purchase or sale of securities. </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://ichallenge.com"> <img className="img-fluid" alt="iChallenge.com" title="iChallenge.com" src="https://cdn.vnoc.com/logos/logo-ichallenge-2-small.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://ichallenge.com">iChallenge.com</a>
											    </h3>
											<p> The best internet challenges. Solve and win online prizes. </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://socialid.com"> <img className="img-fluid" alt="iChallenge.com" title="iChallenge.com" src="https://cdn.vnoc.com/logos/logo-socialid4.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://socialid.com">SocialId.com</a>
											    </h3>
											<p> SocialId helps you get the social name for all major social networking websites. </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://virtualinterns.com"> <img className="img-fluid" alt="virtualinterns.com" title="virtualinterns.com" src="https://cdn.vnoc.com/logos/logo-virtualinterns.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://virtualinterns.com">Virtualinterns.com</a>
											    </h3>
											<p> Join our exclusive community of like minded people on virtualinterns.com </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://referrals.com"> <img className="img-fluid" alt="referrals.com" title="referrals.com" src="https://cdn.vnoc.com/logos/logo-referrals-beta9-small.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://referrals.com">Referrals.com</a>
											    </h3>
											<p> Most effective Business Referral Program and Tools Available. Find and share referrals locally. </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://adrate.com"> <img className="img-fluid" alt="adrate.com" title="adrate.com" src="https://cdn.vnoc.com/logos/logo-adrate-3.png"/> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://adrate.com">Adrate.com</a>
											    </h3>
											<p> Insightful Ad Content Direct To Your Target Market Advertising That Will Reach. Attract. Target. &amp; Engage Your Future Customers </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://consultants.com"> <img className="img-fluid" alt="consultants.com" title="consultants.com" src="https://cdn.vnoc.com/logos/logo-consultants1.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://consultants.com">Consultants.com</a>
											    </h3>
											<p> Find a consultant using our global directory. Request a proposal and get quotes. Or are you looking for consulting jobs? See available job openings. Create your consultant profile and get badges for your consultancy. </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://domaindirectory.com"> <img className="img-fluid" alt="domaindirectory.com" title="domaindirectory.com" src="https://www.domaindirectory.com/images/logo-domaindirectory300x82.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://domaindirectory.com">Domaindirectory.com</a>
											    </h3>
											<p> Domain Directory - Buy, Sell, Trade, Develop, Partner with premium domains on the Domain Directory platform. </p>
										</div>
									</div>
								</div>
								<div className="blur-box text-left">
									<div className="row">
										<div className="col-sm-4">
											<a href="https://handyman.com"> <img className="img-fluid" alt="handyman.com" title="handyman.com" src="https://cdn.vnoc.com/logos/logo-handyman-1.png" /> </a>
										</div>
										<div className="col-sm-8">
											<h3>
												 <a href="https://handyman.com">Handyman.com</a>
											    </h3>
											<p> Handyman.com is the best place to find a professional contractor. </p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default Partner
