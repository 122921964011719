import React from 'react';

const Terms = () => (
	<div>
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-container">
						<div className="page-inner text-center">
						
							<h2 className="text-capitalize text-center"><span className="text-capitalize">{window.Configs.domain}</span> Terms of Use</h2>
							<p className="text-justify">By using the <span className="text-capitalize">{window.Configs.domain}</span> site you are agreeing to comply with these terms of use. <span className="text-capitalize">{window.Configs.domain}</span> also has a privacy policy outlined here: Privacy and by using and accessing the <span className="text-capitalize">{window.Configs.domain}</span> site you are signifying your acknowledgement and agreement to <span className="text-capitalize">{window.Configs.domain}</span>'s privacy policy.</p>
							<h2 className="text-capitalize text-center">Disclaimer and No Warranty</h2>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> does not make any guarantees or warranties as to the accuracy of the content and profiles or messages. <span className="text-capitalize">{window.Configs.domain}</span> is not liable for any inaccuracies on the site content and you are using the site at your own risk. <span className="text-capitalize">{window.Configs.domain}</span> is for informational purposes only and provided "as is" without any warranties or guarantees. By using this site you are agreeing to hold <span className="text-capitalize">{window.Configs.domain}</span> harmless from any harm, loss, damage or injury resulting or arising from your usage of the <span className="text-capitalize">{window.Configs.domain}</span> site.</p>
							<h2 className="text-capitalize text-center">Modifications to this Terms of Use</h2>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> reserves the right at it's sole discretion to change, amend or update this terms of use. It is your responsibility to review the Terms of Use for any updates. And by using this site signifies consent to the right of <span className="text-capitalize">{window.Configs.domain}</span> to change such Terms of Use.</p>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> and its contributors are not liable for incidental, special, punitive, consequential or exemplary damages of any kind, including, without limitation, loss of business, lost revenues or profits, or loss of data in any way related to the <span className="text-capitalize">{window.Configs.domain}</span> site or for any loss or injury or claim based on interruptions, errors, omissions, or other inaccuracies on the <span className="text-capitalize">{window.Configs.domain}</span> site. You agree to indemnify, defend, and hold harmless <span className="text-capitalize">{window.Configs.domain}</span> and any of its content contributors from and against any and all liability and costs, including, without limitation, regulatory penalties, attorney's fees, and costs incurred in connection with any claim arising our of your use of the site or any breach by you of these Terms of Use. <span className="text-capitalize">{window.Configs.domain}</span> shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the site. <span className="text-capitalize">{window.Configs.domain}</span> does not warrant that the site will be uninterrupted, error free, or virus free; nor does <span className="text-capitalize">{window.Configs.domain}</span> make any warranty as to the results to be obtained from use of the site or its Content. In some states jurisdictions limitations of liability are not permitted. In such jurisdictions, some of the foregoing limitations may not apply to you. These limitations shall apply to the fullest extent permitted by law.</p>
							<h2 className="text-capitalize text-center">Comments Sections and Content</h2>
							<p className="text-justify">By submitting Content to us or by posting comments or information on your profile, you are granting or warrant that the owner has express granted, to <span className="text-capitalize">{window.Configs.domain}</span> us a perpetual, irrevocable, royalty-free, and transferable right and license to use and distribute and create derivative works. We may exercise this grant in any platform, media or technology for the full term of any copyright that may exist in such content. You are also hereby granting other affiliates of <span className="text-capitalize">{window.Configs.domain}</span> the rights to use such content in a similar fashion.</p>
							<h2 className="text-capitalize text-center">Content on other sites</h2>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> has not reviewed and are not responsible for the content and actions of any of the sites that <span className="text-capitalize">{window.Configs.domain}</span> links to nor do we make any warranties to the accuracy and/or safety of using such sites or vendors or products and services. <span className="text-capitalize">{window.Configs.domain}</span> has no control over the content or actions of such sites and/or vendors is not responsible nor does it represent or imply that it endorses such website or vendor. You're solely responsible for protecting yourself and technology devices and computers from viruses, worms, Trojan horses, and other harmful or destructive content or privacy violating, or otherwise malicious encounters resulting from such sites as well as any transactions or interaction of any kind. <span className="text-capitalize">{window.Configs.domain}</span> disclaims any responsibility for any harm resulting from your use of non <span className="text-capitalize">{window.Configs.domain}</span> sites and pages.</p>
							<p className="text-justify">As a user of this Web Site you are granted a nonexclusive, nontransferable, revocable, limited license to access and use this Web Site and Content in accordance with these Terms of Use. Provider may terminate this license at any time for any reason.</p>
							<p className="text-justify">The Content on is for your personal use only and not for commercial exploitation. You may not decompile, reverse engineer, disassemble, rent, lease, loan, sell, sublicense, or create derivative works from this Web Site or the Content. Nor may you use any network monitoring or discovery software to determine the site architecture, or extract information about usage, individual identities or users. You may not use any robot, spider, other automatic software or device, or manual process to monitor or copy our Web Site or the Content without Provider's prior written permission. You may not use this Web Site to transmit any false, misleading, fraudulent or illegal communications. You may not copy, modify, reproduce, republish, distribute, display, or transmit for commercial, non-profit or public purposes all or any portion of this Web Site, except to the extent permitted above. You may not use or otherwise export or re-export this Web Site or any portion thereof, or the Content in violation of the export control laws and regulations of the United States of America. Any unauthorized use of this Web Site or its Content is prohibited.</p>
							<p className="text-justify">Nothing submitted to <span className="text-capitalize">{window.Configs.domain}</span> is treated as confidential. The accuracy, completeness, adequacy or currency of the Content is not warranted or guaranteed. Your use of Content on this Web Site or materials linked from this Web Site is at your own risk.</p>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> may contain advertising and sponsorship. Advertisers and sponsors are responsible for ensuring that material submitted for inclusion on this Web Site is accurate and complies with applicable laws. <span className="text-capitalize">{window.Configs.domain}</span> is not be responsible for the illegality of or any error or inaccuracy in advertisers’ or sponsors’ materials or for the acts or omissions of advertisers and sponsors.</p>
							<h2 className="text-capitalize text-center">Disclaimer</h2>
							<p className="text-justify">The <span className="text-capitalize">{window.Configs.domain}</span> site AND THE CONTENT ARE PROVIDED ON AN "AS IS" and "AS AVAILABLE" BASIS. PROVIDER EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. PROVIDER DISCLAIMS ALL RESPONSIBILITY FOR ANY LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM, ARISING OUT OF OR ANY WAY RELATED TO (A) ANY ERRORS IN OR OMISSIONS FROM THIS WEB SITE AND THE CONTENT, INCLUDING, BUT NOT LIMITED TO, TECHNICAL INACCURACIES AND TYPOGRAPHICAL ERRORS, (B) ANY THIRD PARTY CONTENT INCLUDING BUT NOT LIMITED TO ANY ERRORS IN OR OMISSIONS THEREFROM, (C) THE UNAVAILABILITY OF THIS WEB SITE OR ANY PORTION THEREOF, (D) YOUR USE OF THIS WEB SITE OR THE CONTENT, OR (E) YOUR USE OF ANY EQUIPMENT OR SOFTWARE IN CONNECTION WITH THIS WEB SITE.</p>
							<h2 className="text-capitalize text-center">LIMITATION OF LIABILITY.</h2>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> SHALL NOT BE LIABLE FOR ANY LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM YOUR USE OF THIS WEB SITE OR ITS CONTENT. PROVIDER SHALL NOT BE LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER (INCLUDING, WITHOUT LIMITATION, ATTORNEYS' FEES) IN ANY WAY DUE TO, RESULTING FROM, OR ARISING IN CONNECTION WITH THE USE OF OR INABILITY TO USE THIS WEB SITE. We do not endorse any of the profiles or businesses on <span className="text-capitalize">{window.Configs.domain}</span> and use the site at your own risk. We do not have any involvement with the parties or businesses who use this site nor their dealings or any interactions.</p>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> has the right to delete any profile or content at it's sole discretion any time. .</p>
							<p className="text-justify">You agree to indemnify, defend and hold <span className="text-capitalize">{window.Configs.domain}</span> and it's employees and/or contractors and any third party information providers to this Web Site harmless from and against all claims, losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of these Terms of Use by you.</p>
							<p className="text-justify">Minors are not allowed to use <span className="text-capitalize">{window.Configs.domain}</span> and The provisions of paragraphs Disclaimer and Limitation of Liability, are for the benefit of <span className="text-capitalize">{window.Configs.domain}</span> and its employees and/or contractors and any third party information providers to this Web Site. Each of these individuals or entities shall have the right to assert and enforce those provisions directly against you on its own behalf.</p>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> reserves the right to investigate complaints or reported violations of our Terms of Use and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information.</p>
							<p className="text-justify"><span className="text-capitalize">{window.Configs.domain}</span> reserves the right to seek all remedies available at law and in equity for violations of these Terms of Use, including but not limited to the right to block access from a particular internet address to this Web Site and any other Provider web sites and their features.</p>
							<p className="text-justify">The Terms of Use are governed by and construed in accordance with the laws of the State of Delaware and any action arising out of or relating to these terms shall be filed only in state or federal courts located in Delaware and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action.</p>
		
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default Terms
