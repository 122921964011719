import React from 'react';

const Terms = () => (
	<div>
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-container">
						<div className="page-inner text-center">
							<div className="text-center">
							
								<h2 className="text-capitalize text-center"><span className="text-capitalize">{window.Configs.domain}</span> Cookie Policy</h2>
								<p className="desc">This Cookie Policy explains how {window.Configs.domain} LLC., "{window.Configs.domain}" "we", "us", "our," or "{window.Configs.domain}," and our corporate affiliates use cookies and similar technologies to recognize you when you visit our sites on which this Cookie Policy is posted that are official {window.Configs.domain}-branded or operated, with approval, by {window.Configs.domain}. We explain the technologies we use as well as your rights to control our use of them.</p>
								<h2>What is a Cookie?</h2>
								<p className="desc">A cookie is a small data file that is placed on your device when you visit a website. Cookies are widely used in order to make websites work or to work more efficiently, as well as to provide reporting information. A cookie may have unique identifiers and reside, among other places, on your device, in emails we send to you, and on the Platform.</p>
								<p className="desc">Cookies set by {window.Configs.domain} are called "first-party cookies". Cookies set by parties other than {window.Configs.domain} are called "third-party cookies." Third-party cookies enable features or functionality provided by third parties in connection with the Platform, for example, advertising, interactive content, and analytics. The parties that set these third-party cookies can recognize your device both when it visits the website in question and also when it visits certain other websites.</p>
								<p className="desc">We may use other technologies similar to cookies like web beacons, which are sometimes called "tracking pixels" or "clear gifs". These are tiny graphics files that contain a unique identifier that enable us to recognize when someone has visited our Platform or opened an email that we have sent them. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</p>
								<p className="desc">Our Platform may also use "Flash Cookies" (also known as Local Shared Objects or "LSOs"), which are different from browser cookies because of the amount and type of data stored.</p>
								<h2>Why do we use cookies?</h2>
								<p className="desc">We use first-party and third-party cookies for several reasons. Some are required for technical reasons in order for our Platform to operate. Others provide a better experience on our Platform by remembering some of your activities on the Platform (including through our plug-ins, widgets, and embedded content). Other cookies enable more relevant advertising and better analytics.</p>
								<p className="desc"><b>Essential Cookies.</b> Some cookies are required for the Platform to operate, for example, in the following ways:</p>
								<p className="desc">&gt; To identify you as being logged into the Platform</p>
								<p className="desc">&gt; To make sure you connect to the right service on our Platform when we make any changes to the way the Platform works</p>
								<p className="desc"><b>Cookies for Features and Services.</b> Some cookies provide a better experience on our Platform. For example, without cookies to remember some of your settings and activities on our Platform, the following functionality may become unavailable:</p>
								<p className="desc">&gt; Adding purchases to your cart and remembering the products you have added</p>
								<p className="desc">&gt; Remembering settings you have applied, such as layout, text size, preferences, and colors</p>
								<p className="desc">&gt; Remembering if we already have asked you to complete a survey or if you already have engaged with particular content on the Platform to avoid showing these items to you again</p>
								<p className="desc">&gt; Remembering when you have added a product to your Ideabook</p>
								<p className="desc"><b>Cookies for Analytics and Personalization.</b> Some cookies collect information about how you use the Platform to help us improve how our Platform is being used, and to help us understand what may interest you and personalize your experience accordingly. These cookies, for example:</p>
								<p className="desc">&gt; Generate statistics on how the Platform is used, including to measure any errors that occur or to obtain data on the number of users of the Platform that have viewed a product or added it to their Ideabook</p>
								<p className="desc">&gt; Test different designs for the Platform</p>
								<p className="desc">&gt; Help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded</p>
								<p className="desc">&gt; Collect or transmit information about you, such as your browser type and search preferences, and your use of the Platform or third-party sites that incorporate part of the Platform</p>
								<p className="desc"><b>Advertising Cookies.</b> We and third parties use cookies to make advertising more relevant to you. They perform functions like measuring the performance of ads, preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting ads that are based on your visits to our Platform and other sites. These cookies, for example:</p>
								<p className="desc">&gt; Provide feedback to advertising partners that one of their visitors also visited the Platform</p>
								<p className="desc">&gt; Provide our advertisers with data relating to ads that have been displayed to you or that you have clicked on, and the date and time of your use</p>
								<p className="desc">&gt; To show you relevant advertising off of the Platform</p>
								<p className="desc">&gt; To understand what actions you take on the Platform after receiving our advertising</p>
								<p className="desc"><b>Third-Party Cookies.</b> We work with analytics service providers, advertising partners, and advertising networks that may have access to your device information but does not enable them to collect your name, contact details or other personal information unless you choose to provide this information.</p>
								<h2>How can I control cookies</h2>
								<p className="desc">You can amend or "opt out" of the collection of any information through cookies or other tracking technology by actively managing the settings on your browser or mobile device. Please note that turning off or deleting cookies will not prevent device identification and related data collection from occurring. If you choose to reject cookies, your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's or mobile device's technical information for instructions on how to delete and disable cookies, and other tracking/recording tools.</p>
								<p className="desc">In addition you can also "opt-out" of the collection of any information through cookies or other tracking technology of businesses participating through the European Interactive Digital Advertising, the U.S. Network Advertising Initiative or the U.S. Digital Advertising Alliance. Please visit these organizations' opt-out pages to learn about how you may opt out of receiving web-based personalized ads from participating businesses. You can access any settings offered by your mobile operating system to limit ad tracking or install the AppChoices app to learn more about how you may opt out of receiving personalized ads in mobile apps.</p>
								<h2>How often will you update this Cookie Policy?</h2>
								<p className="desc">We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
								<h2>More information about cookies</h2>
								<p className="desc">If you have any questions about our use of cookies or other technologies, please email us at privacy@CONTRIB.com](mailto:privacy@CONTRIB.com). Useful information about cookies can be found at: http://www.allaboutcookies.org</p>
								<h2>More information about analytics</h2>
								<p className="desc">Our Website uses Google Analytics, a web analysis service from Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google"). Google Analytics uses cookies - text files that are stored on your device and make it possible to analyze how you utilize the site. The information generated by the cookie (including the IP address) is transferred and stored on a Google server located in the United States.</p>
								<p className="desc">Google uses the information on our behalf to evaluate how the site is used, create reports about the activities on the site for the site operators, and to perform additional services regarding website and internet utilization.</p>
								<p className="desc">Further you can prevent the collection and processing of cookie created data relating to your utilization of the website (including your IP) via Google by downloading and installing the browser-plugin available under the following link (https://tools.google.com/dlpage/gaoptout?hl=en).</p>
								<p className="desc">You can refuse the use of Google Analytics by clicking on the following link. An opt-out cookie will be set on the device, which prevents the future collection of your data when visiting this website: Disable Google Analytics.</p>
								<p className="desc">Further information concerning the terms and conditions of use and data privacy can be found at http://www.google.com/analytics/terms/gb.html or at https://www.google.de/intl/en_uk/policies/.</p>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default Terms
