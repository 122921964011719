import React from 'react';
import Topsection from './Topsection'
import Midsection from './Midsection';
import Bottomsection from './Bottomsection';
import Brand from './Brand';

const Home = (props) => {
  return (	
	<React.Fragment>	
		<style dangerouslySetInnerHTML={{__html: "\n.navbar {\n\tdisplay: none;\n}\n" }} />
		{/*top section*/}
		<div className="topSection">
			<Topsection/>
		</div>
		{/*middles section*/}
		<div className="midSection d-none">
			<Midsection/>
		</div>
		{/*bottom section*/}
		<div className="bottomSection d-none">			
			<Brand/>
		</div>
		<div className="bottomSection2 d-none">			
			<Bottomsection/>
		</div>
		{/*footer section*/}		
	</React.Fragment>
  );
}

export default Home;