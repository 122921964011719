import React from 'react';

const Index2 = () => (
<div>
<style>
{`
.navbar {  
  padding: 0rem 1rem;
}
.page-container {
min-height: calc(100vh - 50px);
background-position: 50%;
background-size: cover;
background-repeat: no-repeat;
padding-top: 5rem;
padding-bottom: 5rem;
position: relative;
}
.overlay {
background-color: rgba(0, 0, 0, 0.75);
bottom: 0;
left: 0;
position: absolute;
right: 0;
top: 0;
}
`}
</style>	
	<div className="page-container" style={{ backgroundImage: `url(${window.Configs.background_image})` }}>
	<div className="overlay"></div>
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-container1">
						<div className="page-inner text-center">						
							<div className="boxcon1">
								<iframe width="100%" height="948.5" src="https://iphost.com/q1/humor-us-what-do-you-think-is-the-best-platform-for-the-site-park-com" frameborder="0" title="psurvey2"></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
)

export default Index2
